<script lang="ts" setup>
	import { getCountryName } from "~/server/utils/countries";

	const props = defineProps<{
		type: "multiline" | "singleline";
		country: string;
		zip: string;
		state?: string;
		city: string;
		street: string;
	}>();
</script>

<template>
	<span v-if="type === 'singleline' && country === 'us'">{{ props.street }}, {{ props.city }}, {{ props.state?.toUpperCase() }} {{ props.zip }}, {{ getCountryName(props.country) }}</span>

	<span v-if="type === 'multiline' && country === 'us'">
		{{ props.street }},

		{{ props.city }}
		<br />
		{{ props.state?.toUpperCase() }} {{ props.zip }},

		{{ getCountryName(props.country) }}
	</span>
</template>

<style scoped lang="scss"></style>
